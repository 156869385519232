<template>
    <div class="contact-page-container">
        <div class="contact-wrapper">
            <Toast position="bottom-right" />
            <div class="title-container font-libre">
                <span class="h1"> Skontaktuj się z nami! </span>
                <span class="h2">
                    Jeśli produkt który Cię interesuje nie znajduję się w katalogu lub masz inne pytania, zapraszamy do skontaktowania się z nami.
                </span>
            </div>
            <div class="contact-container">
                <div class="contact-items-container">
                    <div class="col-lg-12 col-md-12">
                        <ul class="list-unstyled text-muted">
                            <li><i class="contact-icon pi pi-phone"></i><span class="contact-label">Telefon do siedziby: <a href="tel:126578240">+48 12 657 82 40</a> <br> Obsługa zamówień: <a href="tel:+48606199107"> +48 606 199 107 </a> </span></li>
                            <li><i class="contact-icon pi pi-envelope"></i><span class="contact-label">lusterka@e.pl </span></li>
                            <li><i class="contact-icon pi pi-map"></i><span class="contact-label">Mała Góra 29, 30-864 Kraków </span></li>
                            <li><i class="contact-icon pi pi-clock"></i><span class="contact-label">Godziny otwarcia <br> Pon-Pt: 8:00 - 16:00 </span></li>
                        </ul>
                    </div>
                </div>
                <div class="contact-form-container">
                    <Card class="contact-card">
                        <template #title>
                            Wyślij email
                        </template>
                        <template #content>
                            <form ref="form" class="form-wrapper">
                                <InputText name="name" v-model="name" type="text" class="p-inputtext-sm" placeholder="Imię" />
                                <InputText name="mail" v-model="mail" type="email" class="p-inputtext-sm" placeholder="Adres email" />
                                <InputText name="topic" v-model="topic" type="text" placeholder="Wpisz temat wiadomości" />
                                <InputText name="catalog" v-model="catalog" type="text" class="p-inputtext-sm" placeholder="Numer katalogowy produktu lub nazwa" />
                                <Textarea name="message" placeholder="Wpisz treść wiadomości" v-model="message" :autoResize="true" rows="5" cols="70" />
                                <Button :loading="sending" @click="sendEmail()" label="Wyślij" class="form-button p-button-secondary" />
                            </form>
                        </template>
                    </Card>
                </div>
            </div>
        </div>
        <div class="map-wrapper">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.8520782865385!2d20.02628545146889!3d50.01412767931661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47164386626323a9%3A0x3fd450a4d4f596bf!2s%22ADAM%22%20Lusterka%20samochodowe%2C%20wk%C5%82ady%2C%20podgrzewacze%2C%20p%C5%82ytki%20monta%C5%BCowe!5e0!3m2!1spl!2spl!4v1650527879500!5m2!1spl!2spl" width="100%" height="600" style="border:1px solid black;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
    import Card from 'primevue/card';
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import Button from 'primevue/button';
    import emailjs from '@emailjs/browser';
    import Toast from 'primevue/toast';
    import { useHead } from '@vueuse/head';
    export default {
        components: {
            Card,
            InputText,
            Textarea,
            Button,
            Toast
        },
        data() {
            return {
                meta: useHead({
                // Can be static or computed
                title: 'Lusterka Adam - Kontakt',
                meta: [
                    {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                    {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                    {name: 'description', content: 'P.P.H.U. Import Eksport ADAM jest producentem części do lusterek samochodowych działającycm aktywnie na rynku od poand 20lat. W ofercie znajdują się szkła do lusterek, wkłady oraz ogrzewacze.'},
                    {name: 'keywords', content: 'Lusterka, Szkła do lustrerek, Ogrzewacze, Wkłady, Szkła'}
                ]
                }),
                sending: false,
                topic: '',
                name: '',
                mail: '',
                catalog: '',
                message: '',
                countries: [
                        { "name": "Alfa Romeo", "icon": "icons8-alfa-romeo.svg" },
                        { "name": "Audi", "icon": "icons8-audi.svg" },
                        { "name": "BMW", "icon": "icons8-bmw.svg" },
                        { "name": "Buick", "icon": "icons8-buick.svg" },
                        { "name": "Cadillac", "icon": "icons8-cadillac.svg" },
                        { "name": "Chevrolet", "icon": "icons8-chevrolet.svg" },
                        { "name": "Chrysler", "icon": "icons8-chrysler.svg" },
                        { "name": "Citroen", "icon": "icons8-citroen.png" },
                        { "name": "Dacia", "icon": "dacia_logo.svg" },
                        { "name": "Daewoo", "icon": "daweo.png" },
                        { "name": "Daihatsu", "icon": "icons8-daihatsu-motor-oldest-surviving-japanese-internal-combustion-engine-manufacturers-96.png"},
                        { "name": "Dodge", "icon": "dodge.svg" },
                        { "name": "Ferrari", "icon": "icons8-ferrari.svg" },
                        { "name": "Fiat", "icon": "icons8-fiat.png" },
                        { "name": "Ford", "icon": "icons8-ford.svg" },
                        { "name": "GMC", "icon": "icons8-gmc.svg" },
                        { "name": "Hummer", "icon": "hummer.svg" },
                        { "name": "Honda", "icon": "icons8-honda.svg" },
                        { "name": "Hyundai", "icon": "icons8-hyundai.svg" },
                        { "name": "Infiniti", "icon": "icons8-infiniti.svg" },
                        { "name": "Isuzu", "icon": "icons8-isuzu.png" },
                        { "name": "Jaguar", "icon": "icons8-jaguar.svg" },
                        { "name": "Jeep", "icon": "icons8-jeep.svg" },
                        { "name": "Kia", "icon": "icons8-kia.svg" },
                        { "name": "Lambo", "icon": "icons8-lamborghini.svg" },
                        { "name": "Lexus", "icon": "icons8-lexus.svg" },
                        { "name": "Mazda", "icon": "icons8-mazda.svg" },
                        { "name": "Mercedes", "icon": "icons8-mercedes-benz.svg" },
                        { "name": "Mitsubishi", "icon": "icons8-mitsubishi.svg" },
                        { "name": "Nissan", "icon": "icons8-nissan.svg" },
                        { "name": "Opel", "icon": "opel.svg" },
                        { "name": "Peugeot", "icon": "icons8-peugeot.png" },
                        { "name": "Porsche", "icon": "icons8-porsche.png" },
                        { "name": "Rover", "icon": "rover.svg" },
                        { "name": "Renault", "icon": "icons8-renault.png" },
                        { "name": "Skoda", "icon": "icons8-skoda.png" },
                        { "name": "SAAB", "icon": "saab.svg" },
                        { "name": "Seat", "icon": "seat.svg" },
                        { "name": "Subaru", "icon": "icons8-subaru.png" },
                        { "name": "Suzuki", "icon": "icons8-suzuki.svg" },
                        { "name": "Smart", "icon": "smart.svg" },
                        { "name": "Ssangyong", "icon": "ssangyong.svg" },
                        { "name": "Toyota", "icon": "icons8-toyota.svg" },
                        { "name": "Volkswagen", "icon": "icons8-volkswagen.svg" },
                        { "name": "Volvo", "icon": "icons8-volvo.svg" }
                ],
            }
        },
        methods: {
            sendEmail() {
                this.sending = true
                setTimeout(() => {
                    this.sending = false
                    this.send_done = true
                }, 1000);
                try {
                    emailjs.sendForm('service_9roz271', 'template_7sqae4d', this.$refs.form,
                    'lnom5QXZqPhVwDygO')
                    this.$toast.add({severity:'success', summary: 'Sukces!', detail:'Wiadomość wysłana, postaramy się odpowiedzieć jak najszybciej :)', life: 3000});
                } catch(error) {
                    console.log({error})
                    this.$toast.add({severity:'danger', summary: 'Błąd', detail:'Niestety coś poszło nie tak, spróbuj ponownie później', life: 3000});
                }
                // Reset form field
                this.name = ''
                this.mail = ''
                this.mailfrom = ''
                this.message = ''
                this.topic = ''
                },
            }
        }
</script>

<style scoped>
.contact-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 30rem;
    background-color: #f1f1f1;
    padding: 3rem 2rem 3rem 2rem;
}
.map-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
}
.contact-wrapper {
    display: flex;
    flex-direction: column;
}
.title-container {
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
}
.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.contact-items-container {
    margin-left: 3rem;
    margin-top: 3rem !important;
}
.contact-form-container {
    margin-top: 1rem;
    width: 50%;
    height: 100%;
}
.contact-card {
    min-height: 25rem;
}
.form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.form-wrapper > * {
    margin: 0.2rem 1rem;
    width: 95%;
    font-size: 1rem
}
.contact-items-container {
    width: 50%;
    margin-top: 4rem;
}
@media (max-width: 1000px) {
    .contact-items-container {
        width: 100%;
        margin-left: 0;
        margin-top: 1rem !important;
    }
    .contact-container {
        flex-direction: column;
    }
    .contact-form-container {
        width: 100%;
        height: 100%;
    }
    .title-container {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
    }
}
.map-container {
    width: 50%;
    margin-top: 4rem;
}
.h1 {
    line-height: 1.25; 
    margin: 0 0 10px; 
    font-size: 28px; 
    font-weight: bold;
    color: var(--orange10);
}
.h2 {
    line-height: 1.5; 
    margin: 0 0 10px; 
    font-size: 1em; 
    color: #4b4b4b;
}
.contact-icon {
    font-size: 3rem;
    color: var(--orange10);
    margin-bottom: 1rem;
}
.contact-label {
    font-size: 1em;
}
li {
    display: flex;
    align-items: center;
}
ul > * {
    transition: all .1s;
    margin-bottom: 2rem;
}
.bordered {
    border-bottom: 1px var(--orange10) solid;
}
ul > * > i {
    display: flex;
    align-items: center;
    margin-right: 0.8em;
    margin-top: 10px;
    font-size: 1.3em;
}
a:hover, a:focus {
  text-decoration: none;
}
a:hover {
    width: 100%;
    color: var(--orange10) !important;
}
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 5px;
}
.country-item img {
    width: 17px;
    margin-right: 0.5rem;
}
.form-button {
    background-color: var(--orange10) !important;
    border-color: var(--orange10) !important;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s;
}
</style>